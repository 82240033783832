<template>
  <b-modal
    id="call-panel-finish-attendance-modal"
    size="lg"
    hide-header
    hide-footer
    centered
    @show="onShow"
    @hidden="onHidden"
  >
    <div class="header d-flex flex-row justify-content-between">
      <p class="title">Direcionar paciente</p>
      <Close
        class="close"
        @click="$bvModal.hide('call-panel-finish-attendance-modal')"
      />
    </div>
    <div class="body">
      <div class="row">
        <div class="col-12">
          <b-form-group>
            <label>
              Direcionar paciente para outro departamento
              <span class="help"> (opcional)</span>
            </label>
            <multiselect
              v-model="department"
              track-by="id"
              label="name"
              placeholder="Selecionar..."
              :options="departments"
              :searchable="false"
              :allow-empty="false"
              :showLabels="false"
              class="with-border"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions"> Nada por aqui ainda </template>
            </multiselect>
          </b-form-group>
        </div>
      </div>

      <div class="divider" />

      <div class="row">
        <div v-if="department" class="col-12">
          <b-form-group>
            <div class="d-flex justify-content-between">
              <label>Paciente</label>
              <div class="checkbox-wrapper">
                <Check v-model="is_preferential" />
                <span>Preferencial</span>
              </div>
            </div>
            <PatientInput
              required
              value=""
              v-model="patient"
              :showLabel="false"
              @select="onSelectPatient"
              :customLoadingClass="true"
              :error="validated && department && !patient"
              :disabled="disabledPatient"
            />
          </b-form-group>
        </div>
        <div v-if="appointments.length" class="col-12">
          <b-form-group>
            <label>
              Agendamento
              <span class="help"> (opcional)</span>
            </label>
            <multiselect
              v-model="appointment"
              track-by="id"
              label="label"
              placeholder="Selecionar..."
              :options="appointments"
              :searchable="false"
              :allow-empty="false"
              :showLabels="false"
              class="with-border"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions"> Nada por aqui ainda </template>
            </multiselect>
          </b-form-group>
        </div>
      </div>
    </div>

    <div class="actions">
      <button
        id="cancel-button"
        class="form-control"
        @click="$bvModal.hide('call-panel-finish-attendance-modal')"
      >
        Cancelar
      </button>
      <button
        id="confirm-button"
        class="form-control"
        @click="finishAttendance"
        :disabled="isLoading"
      >
        <b-spinner
          v-if="isLoading"
          small
          variant="primary"
          label="Spinning"
        ></b-spinner>
        <div v-else>
          <span v-if="department">Direcionar</span>
          <span v-else>Finalizar</span>
        </div>
      </button>
    </div>
  </b-modal>
</template>
<script>
import { getCurrentClinic, getCurrentUser } from '@/utils/localStorageManager'
import Close from '@/assets/icons/close.svg'
import ChevronDown from '@/assets/icons/chevron-down.svg'
import PatientInput from '@/components/General/PatientInput'
import Check from '@/components/General/Check'

export default {
  components: { Close, ChevronDown, PatientInput, Check },
  props: {
    callPanelPassword: Object
  },
  data() {
    return {
      validated: false,
      clinic: getCurrentClinic(),
      user: getCurrentUser(),
      department: null,
      patient: null,
      appointment: null,
      is_preferential: false,
      departments: [],
      appointments: [],
      isLoading: false,
      disabledPatient: false
    }
  },
  methods: {
    async onShow() {
      this.getCallPanelDepartments()
      this.fillPatient()
    },
    fillPatient() {
      this.disabledPatient = false
      if (this.callPanelPassword.is_preferential) {
        this.is_preferential = true
      }
      if (
        this.callPanelPassword?.patient &&
        Object.keys(this.callPanelPassword?.patient).length
      ) {
        this.patient = {
          id: this.callPanelPassword.patient.id,
          name: this.callPanelPassword.patient.name
        }
        this.disabledPatient = true
      }
    },
    onHidden() {
      this.validated = false
      this.department = null
      this.patient = null
      this.appointment = null
      this.appointments = []
      this.is_preferential = false
    },
    onSelectPatient(patient) {
      this.getAppointmentsByPatientToday(patient)
    },
    isValid() {
      this.validated = true
      return !this.department || (this.department && this.patient)
    },
    async finishAttendance() {
      if (!this.isValid()) return
      try {
        this.isLoading = true
        this.department && (await this.redirectPassword())
        await this.api.finishCallPanelPasswordAttendance(
          this.callPanelPassword.id
        )
        this.$toast.success('Atendimento finalizado com sucesso')
        this.$emit('finished')
        this.$bvModal.hide('call-panel-finish-attendance-modal')
      } catch (err) {
        this.$toast.error(err.message)
      } finally {
        this.isLoading = false
      }
    },
    async redirectPassword() {
      const data = {
        ...this.callPanelPassword,
        department_id: this.department.id,
        patient_id: this.patient.id,
        appointment_id: this.appointment ? this.appointment.id : null,
        is_preferential: this.is_preferential,
        professional_id: null,
        listener_id: null,
        attendance_start: null,
        status: 'waiting'
      }
      await this.api.createCallPanelPassword(data)
      this.$toast.success('Paciente direcionado com sucesso')
    },
    async getCallPanelDepartments() {
      const res = await this.api.getCallPanelDepartments(this.clinic.id)
      this.departments = res.data.filter(
        el => el.id !== this.callPanelPassword.department_id
      )
    },
    getAppointmentsByPatientToday(patient) {
      this.api
        .getAppointmentsByPatientToday(patient.id)
        .then(res => {
          this.appointments = res.data.map(el => ({
            ...el,
            label: `${this.moment(el.start_datetime).format(
              'DD/MM/YY HH:mm'
            )} - ${el.doctor ? el.doctor.name : el.room.name}`
          }))
        })
        .catch(err => this.$toast.error(err.message))
    }
  }
}
</script>
<style lang="scss">
#call-panel-finish-attendance-modal {
  font-family: 'Nunito Sans';
  .modal-content {
    min-width: 675px;
    .modal-body {
      padding: 0 !important;
      .header {
        padding: 24px;
        border-bottom: 1px solid var(--neutral-200);
        .title {
          font-weight: 600;
          font-size: 18px;
          color: var(--type-active);
          margin: 0;
        }
        .title1 {
          color: #0c1d59;
          font-family: Red Hat Display;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 134%;
        }
        .form-check-inline1 {
          font-family: Nunito Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 150%;
          align-items: center;
          color: #525c7a;
        }
        .close {
          width: 24px;
          height: 24px;
          fill: black;
          cursor: pointer;
        }
      }
      .body {
        padding: 24px;
      }
      .divider {
        width: 100%;
        height: 1px;
        background-color: var(--neutral-200);
        margin-bottom: 15px;
      }
      .checkbox-wrapper {
        position: relative;
        width: 100%;
        display: inline-flex;
        justify-content: flex-end;
        align-items: unset;
        margin-bottom: 0;
      }
      .actions {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        padding: 10px 24px;
        margin-top: 0;

        button {
          font-family: 'Nunito Sans';
          border-radius: 8px;
          font-weight: 700;
          width: fit-content;
        }

        #cancel-button {
          background-color: transparent;
          color: red;
          border: none !important;
          margin-right: 10px;

          &:hover {
            background-color: #00000018;
          }
        }

        #confirm-button {
          background-color: #305bf2;
          color: white;
        }
      }
    }
  }
}
</style>
