<template>
  <b-modal
    :visible="modalVisible"
    id="batch-redirect-patients-modal"
    title="Redirecionar pacientes"
    size="lg"
    hide-header
    hide-footer
    centered
    @hide="closeModal"
  >
    <div class="header d-flex flex-row justify-content-between">
      <p class="title">Redirecionar pacientes</p>
      <Close class="close" @click="closeModal" />
    </div>
    <div class="body">
      <div class="row mb-2">
        <b-col cols="12">
          <b-form-group>
            <label> Direcionar paciente para departamento </label>
            <multiselect
              v-model="department"
              track-by="id"
              label="name"
              placeholder="Selecionar..."
              :options="departmentsList"
              :searchable="false"
              :allow-empty="false"
              :showLabels="false"
              class="with-border"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions"> Nada por aqui ainda </template>
            </multiselect>
          </b-form-group>
        </b-col>
      </div>
      <div class="divider" />
      <div v-if="department" class="row mb-2">
        <b-col cols="12">
          <b-form-group>
            <label>Paciente</label>
            <PatientMultiselect
              required
              v-model="patients"
              :showLabel="false"
              :patientsList="patientsList"
              placeholder=""
              :customLoadingClass="true"
            >
            </PatientMultiselect>
          </b-form-group>
        </b-col>
      </div>
      <div class="actions">
        <button id="cancel-button" class="form-control" @click="closeModal">
          Cancelar
        </button>
        <button
          id="confirm-button"
          class="form-control"
          @click="batchRedirectPassword"
          :disabled="isLoading"
        >
          <b-spinner
            v-if="isLoading"
            small
            variant="primary"
            label="Spinning"
          ></b-spinner>
          <span v-else>Confirmar</span>
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Close from '@/assets/icons/close.svg'
import PatientMultiselect from '@/components/CallPanel/PatientMultiselect'
import ChevronDown from '@/assets/icons/chevron-down.svg'
import { mapActions } from 'vuex'

export default {
  components: {
    Close,
    PatientMultiselect,
    ChevronDown
  },
  props: {
    modalVisible: Boolean,
    departmentData: Object,
    nextPasswords: Array
  },
  data() {
    return {
      patients: null,
      patientsList: [],
      department: null,
      departmentsList: [],
      preferential: false,
      appointments: [],
      appointment: null,
      isLoading: false
    }
  },
  methods: {
    ...mapActions('callPanel', ['loadNextPasswords']),
    closeModal() {
      this.$emit('update:modalVisible', false)
      this.patients = null
      this.department = null
    },
    async processPatients() {
      const passwordsArray = []
      const idsArray = []
      this.patients.map(el => {
        passwordsArray.push({
          password: 'ENC000',
          department_id: this.department.id,
          patient_id: el.patient.id,
          appointment_id: null,
          is_preferential: el.is_preferential,
          professional_id: null,
          listener_id: null,
          attendance_start: null,
          status: 'waiting'
        })
        idsArray.push(el.id)
      })

      await this.api.batchCreateCallPanelPassword(passwordsArray)

      await this.api.batchFinishCallPanelPasswordAttendance(idsArray).then(async () => {
        await this.loadNextPasswords(this.departmentData.department.id)
        this.$toast.success('Pacientes encaminhados com sucesso!')
      })

      // await Promise.all(tasks)
    },
    async batchRedirectPassword() {
      if(this.patients.length === 0) {
        this.$toast.error('Selecione ao menos um paciente para encaminhar')
        return
      }
      if(!this.department) {
        this.$toast.error('Selecione um departamento para encaminhar os pacientes')
        return
      }
      this.isLoading = true
      try {
        await this.processPatients()
      } catch (error) {
        this.$toast.error('Ocorreu um erro ao encaminhar os pacientes: ', error)
      } finally {
        this.isLoading = false
        this.closeModal()
      }
    }
  },
  watch: {
    departmentData: {
      handler(newVal) {
        this.department = null;
        this.departmentsList = [];
        if(newVal.departments.length){
          newVal.departments.map(el => {
            if (el.id !== newVal.department.id) {
              this.departmentsList.push({ id: el.id, name: el.name })
            }
          })
        }
      },
      deep: true
    },
    nextPasswords: {
      handler(newVal) {
        this.patientsList = newVal
      },
      deep: true
    },
  }
}
</script>

<style lang="scss">
#batch-redirect-patients-modal {
  font-family: 'Nunito Sans';

  .modal-content {
    .modal-body {
      padding: 0 !important;

      .header {
        padding: 24px;
        border-bottom: 1px solid var(--neutral-200);

        .title {
          font-weight: 600;
          font-size: 18px;
          color: var(--type-active);
          margin: 0;
        }

        .title1 {
          color: #0c1d59;
          font-family: Red Hat Display;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 134%;
        }

        .form-check-inline1 {
          font-family: Nunito Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 150%;
          align-items: center;
          color: #525c7a;
        }

        .close {
          width: 24px;
          height: 24px;
          fill: black;
          cursor: pointer;
        }
      }

      .body {
        padding: 24px;

        .checkbox-wrapper {
          position: relative;
          width: 100%;
          display: inline-flex;
          justify-content: flex-end;
          align-items: unset;
          margin-bottom: 0;
        }

        .actions {
          display: flex;
          justify-content: flex-end;
          width: 100%;
          margin-top: 0;

          button {
            font-family: 'Nunito Sans';
            border-radius: 8px;
            font-weight: 700;
            width: fit-content;
          }

          #cancel-button {
            background-color: transparent;
            color: red;
            border: none !important;
            margin-right: 10px;

            &:hover {
              background-color: #00000018;
            }
          }

          #confirm-button {
            background-color: #305bf2;
            color: white;
          }
        }
      }

      .divider {
        width: 100%;
        height: 1px;
        background-color: var(--neutral-200);
        margin-bottom: 15px;
      }
    }
  }
}
</style>
