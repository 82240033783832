<template>
  <div class="card-container">
    <div class="card-title">Senha atual</div>

    <div class="password-wrapper">
      <CallPanelPatientModule
        v-if="!editMode"
        currentMode
        :callPanelPassword="callPanelPassword"
      />

      <div v-else class="password-input-wrapper">
        <b-form-input
          v-model="manualPassword"
          autocomplete="off"
          maxLength="7"
          type="text"
          class="password-input"
        />
        <Icon v-if="manualPassword" tooltip="Chamar senha agora">
          <Call v-if="!disabled" class="pointer" @click="callPassword()" />
          <Call v-else class="disabled-call disabled-cursor" />
        </Icon>
      </div>

      <div
        v-if="!callPanelPassword || !callPanelPassword.patient"
        class="edit-button"
        @click="!editMode ? startEditMode() : cancelEditMode()"
      >
        {{ !editMode ? 'Editar senha atual' : 'Cancelar edição' }}
      </div>
    </div>

    <div class="actions">
      <button
        v-if="callPanelPassword && callPanelPassword?.status !== 'finished'"
        class="custom-button main-button"
        @click="$bvModal.show('call-panel-finish-attendance-modal')"
      >
        Direcionar atendimento
      </button>
      <button
        v-if="
          (callPanelPassword && callPanelPassword?.status === 'finished') ||
          (!callPanelPassword && nextPasswords.length)
        "
        class="custom-button main-button"
        @click="nextPassword"
        :disabled="!nextPasswords.length"
        :class="{ 'disabled-cursor': loadingDiscard, 'disabled-next-password': !nextPasswords.length }"
        :title="!nextPasswords.length ? 'Não há senhas para chamar' : 'Chamar próxima senha'"
      >
        Próxima senha
        <ChevronRight class="chevron-action" />
      </button>
      <button
        v-if="callPanelPassword && callPanelPassword?.status !== 'finished'"
        class="custom-button secondary-button"
        @click="discardAndNext(callPanelPassword)"
        :disabled="loadingDiscard"
        :class="{ 'disabled-cursor': loadingDiscard }"
      >
        <b-spinner
          v-if="loadingDiscard"
          small
          variant="primary"
          label="Spinning"
        ></b-spinner>
        Pular senha
        <ChevronRight class="chevron-action" />
      </button>
    </div>

    <div class="divider" />

    <b-table-simple class="table">
      <thead>
        <tr>
          <td class="td-header">
            <div class="d-flex">
              <div
                class="filter-badge pointer"
                :class="{
                  'badge-active': selectedFilter === 'history',
                  'badge-inactive': selectedFilter !== 'history'
                }"
                @click="selectedFilter = 'history'"
              >
                Histórico de senhas
              </div>
              <div
                class="filter-badge pointer"
                :class="{
                  'badge-active': selectedFilter === 'skipped',
                  'badge-inactive': selectedFilter !== 'skipped'
                }"
                @click="selectedFilter = 'skipped'"
              >
                Senhas puladas
              </div>
            </div>
          </td>
          <td
            v-if="!loadingCalledPasswords && filteredCalledPasswords?.length"
            class="td-header clear-button pointer"
            @click="clearPasswords"
          >
            Limpar
          </td>
          <td v-else class="border-0"></td>
        </tr>
      </thead>
      <tbody v-if="!loadingCalledPasswords && filteredCalledPasswords.length">
        <tr
          v-for="calledPassword in filteredCalledPasswords"
          :key="calledPassword.id"
        >
          <td class="td-name-password">
            <CallPanelPatientModule :callPanelPassword="calledPassword" />
          </td>
          <td>
            <Icon tooltip="Chamar novamente">
              <Call
                v-if="!disabled"
                class="pointer"
                @click="callPasswordAgain(calledPassword)"
              />
              <Call v-else class="disabled-call disabled-cursor" />
            </Icon>
          </td>
        </tr>
      </tbody>
      <tbody
        v-else-if="!loadingCalledPasswords && !filteredCalledPasswords.length"
      >
        <tr></tr>
        <tr>
          <td colspan="3" class="text-center">
            <strong>Nenhuma senha para exibir</strong>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr v-for="el in Array.from(Array(6).keys())" :key="el">
          <td class="td-name-password">
            <b-skeleton animation="throb" width="100%"></b-skeleton>
          </td>
          <td>
            <b-skeleton animation="throb" width="100%"></b-skeleton>
          </td>
        </tr>
      </tbody>
    </b-table-simple>

    <CallPanelFinishAttendanceModal
      :callPanelPassword="callPanelPassword"
      @finished="onFinished"
    />

    <div class="pagination-position">
      <b-pagination
        v-model="page"
        :total-rows="count"
        :per-page="limit"
        first-number
        last-number
        size="sm"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import { getCurrentUser, getCurrentClinic } from '@/utils/localStorageManager'
import Call from '@/assets/icons/call.svg'
import Icon from '@/components/General/Icon'
import ChevronRight from '@/assets/icons/chevron-right.svg'
import CallPanelPatientModule from './CallPanelPatientModule'
import CallPanelFinishAttendanceModal from './CallPanelFinishAttendanceModal'

export default {
  components: {
    Call,
    Icon,
    CallPanelPatientModule,
    ChevronRight,
    CallPanelFinishAttendanceModal
  },
  mounted() {
    this.pannelChannel = this.pusher.subscribe(`call-pannel-${this.clinic.id}`);
    this.pannelChannel.bind('call-panel-password', this.receivedPassword, this)
    this.channel.bind('call-panel-password', this.receivedPassword, this)
  },
  computed: {
    ...mapGetters('pusher', ['channel']),
    ...mapState('callPanel', [
      'callPanelPassword',
      'department',
      'listener',
      'nextPasswords'
    ]),
    filteredCalledPasswords() {
      return this.calledPasswords.filter(password => {
        if (this.selectedFilter === 'skipped') {
          return password.status === 'discard'
        } else {
          return password
        }
      })
    }
  },
  data() {
    return {
      user: getCurrentUser(),
      clinic: getCurrentClinic(),
      editMode: false,
      manualPassword: null,
      calledPasswords: [],
      loadingCalledPasswords: false,
      loadingDiscard: false,
      page: 1,
      count: 0,
      limit: 0,
      selectedFilter: 'history',
      disabled: false
    }
  },
  methods: {
    ...mapActions('callPanel', ['getCurrentPassword', 'loadNextPasswords']),
    async nextPassword() {
      try {
        this.loadingDiscard = true
        await this.api.callNext(
          this.department.id,
          this.user.id,
          this.listener?.id
        )
        this.getCurrentPassword()
        this.getCalledPasswords(true)
      } catch (err) {
        this.$toast.error(err.message)
      } finally {
        this.loadingDiscard = false
      }
    },
    async callPassword() {
      if (!this.manualPassword) return
      try {
        this.disabled = true
        const data = {
          department_id: this.department.id,
          professional_id: this.user.id,
          listener_id: this.listener ? this.listener.id : null,
          password: this.manualPassword,
          status: 'started'
        }
        await this.api.createCallPanelPassword(data)
        this.getCurrentPassword()
        this.getCalledPasswords(true)
        this.editMode = false
      } catch (err) {
        this.$toast.error(err.message)
      } finally {
        this.disabled = false
      }
    },
    async clearPasswords() {
      try {
        let status
        let alertStatus
        if (this.selectedFilter === 'skipped') {
          status = 'discard'
          alertStatus = 'senhas puladas'
        } else {
          status = 'all'
          alertStatus = 'histórico de senhas'
        }

        this.$swal({
          title: `Limpar ${alertStatus}`,
          html: `Essa ação não poderá ser desfeita. Deseja realmente limpar <span>${alertStatus}</span>?`,
          showCancelButton: true,
          confirmButtonColor: '#305BF2',
          confirmButtonText: 'Confirmar',
          cancelButtonColor: 'transparent',
          cancelButtonText: '<span style="color: red;">Cancelar</span>',
          cancelButtonHoverColor: '#ff0000',
          showCloseButton: true,
          reverseButtons: true,
          customClass: {
            popup: 'custom-swal-clear-passwords'
          }
        }).then(res => {
          if (res.isConfirmed) {
            this.loadingCalledPasswords = true
            const currentId = this.callPanelPassword.id
            this.api
              .clearCallPanelPasswords(currentId, status, this.department.id)
              .then(() => {
                this.getCurrentPassword()
                this.getCalledPasswords(false)
                this.editMode = false
              })
              .catch(err => {
                this.$toast.error(err.message)
                this.loadingCalledPasswords = false
              })
          }
        })
      } catch (err) {
        this.$toast.error(err.message)
      }
    },
    async callPasswordAgain(callPanelPassword) {
      try {
        this.disabled = true
        await this.api.callNow(
          callPanelPassword.id,
          this.user.id,
          this.listener?.id
        )
        this.getCurrentPassword()
        this.getCalledPasswords(true)
      } catch (err) {
        this.$toast.error(err.message)
      } finally {
        this.disabled = false
      }
    },
    startEditMode() {
      this.manualPassword = this.callPanelPassword
        ? this.callPanelPassword.password
        : null
      this.editMode = true
    },
    cancelEditMode() {
      this.manualPassword = null
      this.editMode = false
    },
    onFinished() {
      this.getCurrentPassword()
      this.getCalledPasswords(true)
    },
    discardAndNext(callPanelPassword) {
      let passwordId
      if (
        callPanelPassword.patient &&
        Object.keys(callPanelPassword.patient).length &&
        callPanelPassword.patient.name
      ) {
        passwordId = callPanelPassword.patient.name
      } else {
        passwordId = callPanelPassword.password
      }
      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">Deseja realmente descartar a senha <span>${passwordId}</span> e chamar a próxima?</div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
      }).then(res => {
        if (res.isConfirmed) {
          this.loadingDiscard = true
          this.api
            .discardAndNext(callPanelPassword.id, this.user.id)
            .then(() => {
              this.getCurrentPassword()
              this.getCalledPasswords(true)
            })
            .catch(err => {
              this.$toast.error(err.message)
            })
            .finally(() => {
              this.loadingDiscard = false
            })
        }
      })
    },
    getCalledPasswords(hideLoading = false) {
      this.loadingCalledPasswords = !hideLoading
      this.api
        .getCalledPasswords(this.page, this.department.id)
        .then(res => {
          this.calledPasswords = res.data.data
          this.count = res.data.total
          this.limit = res.data.per_page
        })
        .catch(err => {
          this.$toast.error(err.message)
        })
        .finally(() => {
          this.loadingCalledPasswords = false
        })
    },
    receivedPassword(data) {
      if (data?.department?.id === this.department?.id) {
        this.loadNextPasswords(this.department.id)
      }
    }
  },
  watch: {
    page() {
      this.getCalledPasswords()
    },
    department(val) {
      val && this.getCurrentPassword()
      val && this.getCalledPasswords()
    },
    callPanelPassword() {
      this.loadNextPasswords(this.department.id)
    }
  }
}
</script>
<style lang="scss" scoped>
.card-container {
  width: 100%;
  padding: 24px;
  border: 1px solid var(--neutral-200);
  border-radius: 8px;
  background-color: white;
}
.password-wrapper {
  margin: 20px 0;

  .edit-button {
    font-size: 12px;
    font-weight: 600;
    color: var(--blue-500);
    padding: 5px 0;
    cursor: pointer;
    width: fit-content;
  }
}
.divider {
  width: 100%;
  height: 1px;
  background-color: var(--neutral-200);
  margin-bottom: 20px;
}
.table {
  margin: 0;
  .td-header {
    border: none;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
  .td-name-password {
    width: 100%;
    padding-left: 0;
  }
  td {
    vertical-align: middle;
    border-top: none;
    border-bottom: 1px solid var(--neutral-200);
  }
}
.pointer {
  cursor: pointer;
}
.card-title {
  font-weight: 700;
  font-size: 16px;
  color: var(--dark-blue);
}
.actions {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}
.chevron-action {
  width: 20px;
  height: 20px;
  stroke: var(--blue-500);
}
.password-input-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  .password-input {
    max-width: 200px !important;
    font-size: 28px !important;
    font-weight: 700 !important;
    color: var(--blue-500) !important;
    text-transform: uppercase;
  }
}
.pagination-position {
  padding: 20px 0 0 0;
}

.custom-button {
  font-weight: bold;
  height: 40px;
  padding: 8px 16px 8px 16px;
  border: none;
  border-radius: 8px;
  transition: 0.3s;
  color: var(--blue-500);
}

.main-button {
  background-color: var(--blue-100);

  &:hover {
    background-color: var(--neutral-200);
  }
}

.secondary-button {
  background: none;

  &:hover {
    background-color: rgb(241, 240, 240);
  }
}

.filter-badge {
  height: 32px;
  border-radius: 100px;
  border: 1px solid var(--neutral-200);
  padding: 4px 8px 4px 8px;
  margin-right: 10px;
  font-size: 16px;
  font-weight: 600;
}

.badge-active {
  border: 1px solid var(--neutral-100);
  background-color: var(--neutral-100);
  color: var(--neutral-700);
}

.badge-inactive {
  border: 1px solid var(--neutral-200);
  background-color: white;
  color: #525c7a;
}
.clear-button {
  color: var(--blue-500);
  font-weight: 700;
  font-size: 16px;
}

.disabled-call {
  stroke: var(--neutral-200);
  opacity: 0.5;
}

.disabled-cursor {
  cursor: not-allowed;
}

.disabled-next-password {
  color: var(--blue-300);
  background-color: var(--neutral-100);
  cursor: not-allowed;
}
</style>
