<template>
  <div>
    <div v-if="callPanelPassword && callPanelPassword.patient" class="patient-module-container">
      <Avatar :size="currentMode ? '60' : '40'" :src="callPanelPassword.patient.photo" :status="callPanelPassword.appointment ? callPanelPassword.appointment.status : null" />
      <div>
        <div class="name-or-password" :class="{ currentMode: currentMode }">
          <!-- {{ parseName(callPanelPassword.patient.name) }} -->
          {{ callPanelPassword.patient.name }}
          <Icon v-if="callPanelPassword.is_preferential" tooltip="Preferencial">
            <span class="preferential-icon">P</span>
          </Icon>
          <small v-if="callPanelPassword.status === 'discard'" class="skipped">(Senha pulada)</small>
        </div>
        <div v-if="callPanelPassword.patient.birthday" class="birthday">
          {{ getAge(callPanelPassword.patient.birthday) }} anos ({{ getDate(callPanelPassword.patient.birthday) }})
        </div>
      </div>
    </div>
    <div v-else class="name-or-password" :class="{ currentMode, currentModePass: currentMode }">
      {{ callPanelPassword ? callPanelPassword.password : '000' }}
      <Icon v-if="callPanelPassword && callPanelPassword.is_preferential" tooltip="Preferencial">
        <span class="preferential-icon">P</span>
      </Icon>
      <small v-if="callPanelPassword?.status === 'discard'" class="skipped">(Senha pulada)</small>
    </div>
    <!-- <div v-if="!currentMode">
      {{ callPanelPassword ? callPanelPassword.status : '' }}
    </div> -->
  </div>
</template>
<script>
import { parseName } from '@/utils/callPanelHelper'
import { getAge } from '@/utils/timeHelper'

export default {
  components: { 
    Avatar: () => import('@/components/General/Avatar'), 
    Icon: () => import('@/components/General/Icon'), 
  },
  props: {
    callPanelPassword: Object,
    currentMode: { type: Boolean, default: false }
  },
  methods: {
    parseName,
    getAge,
    getDate(date) {
      return this.moment(date).format('DD/MM/YYYY')
    },
  }
}
</script>
<style lang="scss" scoped>
.patient-module-container {
  display: inline-flex;
  align-items: center;
  gap: 10px;
}
.name-or-password {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 600;
  font-size: 14px;
  color: var(--type-active);
  text-overflow: ellipsis;
}
.birthday {
  font-weight: 400;
  font-size: 13px;
  color: var(--type-active);
}
.currentMode {
  font-size: 28px;
}
.currentModePass {
  font-weight: 700;
  color: var(--blue-500);
}
.preferential-icon {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--orange);
  color:white;
  font-size: 11px;
  font-weight: 700;
  padding-top: 2px;
  border-radius: 50%;
}

.skipped {
  margin-left: 10px;
  font-size: 12px;
  font-weight: 400;
  color: #8696AC;
}
</style>
