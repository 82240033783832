var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-container"},[_c('div',{staticClass:"card-title"},[_vm._v("Senha atual")]),_c('div',{staticClass:"password-wrapper"},[(!_vm.editMode)?_c('CallPanelPatientModule',{attrs:{"currentMode":"","callPanelPassword":_vm.callPanelPassword}}):_c('div',{staticClass:"password-input-wrapper"},[_c('b-form-input',{staticClass:"password-input",attrs:{"autocomplete":"off","maxLength":"7","type":"text"},model:{value:(_vm.manualPassword),callback:function ($$v) {_vm.manualPassword=$$v},expression:"manualPassword"}}),(_vm.manualPassword)?_c('Icon',{attrs:{"tooltip":"Chamar senha agora"}},[(!_vm.disabled)?_c('Call',{staticClass:"pointer",on:{"click":function($event){return _vm.callPassword()}}}):_c('Call',{staticClass:"disabled-call disabled-cursor"})],1):_vm._e()],1),(!_vm.callPanelPassword || !_vm.callPanelPassword.patient)?_c('div',{staticClass:"edit-button",on:{"click":function($event){!_vm.editMode ? _vm.startEditMode() : _vm.cancelEditMode()}}},[_vm._v(" "+_vm._s(!_vm.editMode ? 'Editar senha atual' : 'Cancelar edição')+" ")]):_vm._e()],1),_c('div',{staticClass:"actions"},[(_vm.callPanelPassword && _vm.callPanelPassword?.status !== 'finished')?_c('button',{staticClass:"custom-button main-button",on:{"click":function($event){return _vm.$bvModal.show('call-panel-finish-attendance-modal')}}},[_vm._v(" Direcionar atendimento ")]):_vm._e(),(
        (_vm.callPanelPassword && _vm.callPanelPassword?.status === 'finished') ||
        (!_vm.callPanelPassword && _vm.nextPasswords.length)
      )?_c('button',{staticClass:"custom-button main-button",class:{ 'disabled-cursor': _vm.loadingDiscard, 'disabled-next-password': !_vm.nextPasswords.length },attrs:{"disabled":!_vm.nextPasswords.length,"title":!_vm.nextPasswords.length ? 'Não há senhas para chamar' : 'Chamar próxima senha'},on:{"click":_vm.nextPassword}},[_vm._v(" Próxima senha "),_c('ChevronRight',{staticClass:"chevron-action"})],1):_vm._e(),(_vm.callPanelPassword && _vm.callPanelPassword?.status !== 'finished')?_c('button',{staticClass:"custom-button secondary-button",class:{ 'disabled-cursor': _vm.loadingDiscard },attrs:{"disabled":_vm.loadingDiscard},on:{"click":function($event){return _vm.discardAndNext(_vm.callPanelPassword)}}},[(_vm.loadingDiscard)?_c('b-spinner',{attrs:{"small":"","variant":"primary","label":"Spinning"}}):_vm._e(),_vm._v(" Pular senha "),_c('ChevronRight',{staticClass:"chevron-action"})],1):_vm._e()]),_c('div',{staticClass:"divider"}),_c('b-table-simple',{staticClass:"table"},[_c('thead',[_c('tr',[_c('td',{staticClass:"td-header"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"filter-badge pointer",class:{
                'badge-active': _vm.selectedFilter === 'history',
                'badge-inactive': _vm.selectedFilter !== 'history'
              },on:{"click":function($event){_vm.selectedFilter = 'history'}}},[_vm._v(" Histórico de senhas ")]),_c('div',{staticClass:"filter-badge pointer",class:{
                'badge-active': _vm.selectedFilter === 'skipped',
                'badge-inactive': _vm.selectedFilter !== 'skipped'
              },on:{"click":function($event){_vm.selectedFilter = 'skipped'}}},[_vm._v(" Senhas puladas ")])])]),(!_vm.loadingCalledPasswords && _vm.filteredCalledPasswords?.length)?_c('td',{staticClass:"td-header clear-button pointer",on:{"click":_vm.clearPasswords}},[_vm._v(" Limpar ")]):_c('td',{staticClass:"border-0"})])]),(!_vm.loadingCalledPasswords && _vm.filteredCalledPasswords.length)?_c('tbody',_vm._l((_vm.filteredCalledPasswords),function(calledPassword){return _c('tr',{key:calledPassword.id},[_c('td',{staticClass:"td-name-password"},[_c('CallPanelPatientModule',{attrs:{"callPanelPassword":calledPassword}})],1),_c('td',[_c('Icon',{attrs:{"tooltip":"Chamar novamente"}},[(!_vm.disabled)?_c('Call',{staticClass:"pointer",on:{"click":function($event){return _vm.callPasswordAgain(calledPassword)}}}):_c('Call',{staticClass:"disabled-call disabled-cursor"})],1)],1)])}),0):(!_vm.loadingCalledPasswords && !_vm.filteredCalledPasswords.length)?_c('tbody',[_c('tr'),_c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"3"}},[_c('strong',[_vm._v("Nenhuma senha para exibir")])])])]):_c('tbody',_vm._l((Array.from(Array(6).keys())),function(el){return _c('tr',{key:el},[_c('td',{staticClass:"td-name-password"},[_c('b-skeleton',{attrs:{"animation":"throb","width":"100%"}})],1),_c('td',[_c('b-skeleton',{attrs:{"animation":"throb","width":"100%"}})],1)])}),0)]),_c('CallPanelFinishAttendanceModal',{attrs:{"callPanelPassword":_vm.callPanelPassword},on:{"finished":_vm.onFinished}}),_c('div',{staticClass:"pagination-position"},[_c('b-pagination',{attrs:{"total-rows":_vm.count,"per-page":_vm.limit,"first-number":"","last-number":"","size":"sm"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }