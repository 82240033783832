<template>
  <div :style="{ display: nextPasswords.length ? 'block' : 'none' }" class="card-container">
    <b-table-simple class="table">
      <thead>
        <tr>
          <td class="td-header">
            <div class="d-flex">
              <div
                class="filter-badge pointer"
                :class="{'badge-active': selectedFilter === 'common', 'badge-inactive': selectedFilter !== 'common'}"
                @click="selectedFilter = 'common'"
              >
                Próximas senhas
              </div>
              <div
                class="filter-badge pointer"
                :class="{'badge-active': selectedFilter === 'preferential', 'badge-inactive': selectedFilter !== 'preferential'}"
                @click="selectedFilter = 'preferential'"
              >
                Preferencial
              </div>
            </div>
          </td>
          <td class="td-header">
            <div v-if="filteredPasswords.length" class="card-title">Chegada</div>
          </td>
          <td class="td-header" />
        </tr>
      </thead>
      <tbody v-if="filteredPasswords.length">
        <tr v-for="nextPassword in filteredPasswords" :key="nextPassword.id">
          <td>
            <CallPanelPatientModule
              :callPanelPassword="nextPassword"
            />
          </td>
          <td>
            <div class="time">{{ getTime(nextPassword.created_at) }}</div>
            <div class="difference-time">{{ parseTime(nextPassword.created_at) }}</div>
          </td>
          <td>
            <Icon tooltip="Chamar senha agora" class="pointer">
              <Call @click="callPassword(nextPassword)"/>
            </Icon>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr></tr>
        <tr>
          <td colspan="3" class="text-center"><strong>Nenhuma senha para exibir</strong></td>
        </tr>
      </tbody>
    </b-table-simple>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import { getCurrentUser } from '@/utils/localStorageManager'
import { parseTime } from '@/utils/timeHelper'
import Call from '@/assets/icons/call.svg'
import Icon from '@/components/General/Icon'
import CallPanelPatientModule from './CallPanelPatientModule'
export default {
  props: {
    redirected: Boolean
  },
  components: { Icon, Call, CallPanelPatientModule },
  computed: {
    ...mapState('callPanel', ['listener', 'nextPasswords']),
    filteredPasswords() {
      return this.nextPasswords.filter(password => {
        if (this.selectedFilter === 'common') {
          return password;
        }
        return password.is_preferential;
      })
    }
  },
  data() {
    return {
      user: getCurrentUser(),
      selectedFilter: 'common'
    }
  },
  methods: {
    ...mapActions('callPanel', ['getCurrentPassword']),
    parseTime,
    getTime(date) {
      return this.moment(date).format('HH[h]mm')
    },
    async callPassword(callPanelPassword) {
      try {
        await this.api.callNow(callPanelPassword.id, this.user.id, this.listener?.id)
        this.getCurrentPassword()
      } catch (err) {
        this.$toast.error(err.message)
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.card-container {
  width: 100%;
  padding: 24px;
  border: 1px solid var(--neutral-200);
  border-radius: 8px;
  background-color: white;
}
.card-title {
  font-weight: 700;
  font-size: 16px;
  color: var(--dark-blue);
}
.table {
  margin: 0;
  .td-header {
    border: none;
    padding-top: 0;
    padding-bottom: 0;
  }
  td {
    vertical-align: middle;
    border-top: none;
    border-bottom: 1px solid var(--neutral-200);
  }
}
.time {
  font-weight: 600;
  font-size: 14px;
  color: var(--type-active);
}
.difference-time {
  font-weight: 400;
  font-size: 13px;
  color: var(--type-active);
}
.pointer {
  cursor: pointer;
}
.pagination-position {
  padding: 20px 0 0 0;
}
.filter-badge {
  height: 32px;
  border-radius: 100px;
  border: 1px solid var(--neutral-200);
  padding: 4px 8px 4px 8px;
  margin-right: 10px;
  font-size: 16px;
  font-weight: 600;
}
.badge-active {
  border: 1px solid var(--neutral-100);
  background-color: var(--neutral-100);
  color: var(--neutral-700);
}
.badge-inactive {
  border: 1px solid var(--neutral-200);
  background-color: white;
  color: #525C7A;
}

</style>
