<template>
  <div @click="$bvModal.show('call-panel-modal')">
    <Icon class="pointer" tooltip="Painel de senhas">
      <TV style="fill: rgb(127, 145, 210)"/>
    </Icon>
    <CallPanelModal />
  </div>
</template>
<script>
import Icon from '@/components/General/Icon'
import TV from '@/assets/icons/tv.svg'
import CallPanelModal from './CallPanelModal';

export default {
  components: { Icon, TV, CallPanelModal },
}
</script>
