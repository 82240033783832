<template>
  <b-modal
    id="call-panel-modal"
    :size="nextPasswords.length ? 'xl' : 'lg'"
    hide-header
    hide-footer
    centered
    @show="onShow"
  >
    <div class="header d-flex flex-row justify-content-between">
      <p class="title">Painel de Chamadas</p>
      <Close
        class="close"
        @click="$bvModal.hide('call-panel-modal')"
      />
    </div>
    <div class="body">

      <div class="row mb-2">
        <b-col :cols="nextPasswords?.length ? '3' : '4'" class="selects">
          <b-form-group>
            <label>Definir departamento</label>
            <multiselect
              :value="department"
              track-by="id"
              label="name"
              placeholder="Selecionar..."
              :options="departments"
              :searchable="false"
              :allow-empty="false"
              :showLabels="false"
              class="with-border"
              @select="setDepartment"
              title="name"
            >
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>
            <template slot="noOptions">
              Nada por aqui ainda
            </template>
            <template slot="singleLabel" slot-scope="{ option }">
              <span :title="option.name">{{ option.name.length > 20 ? option.name.substring(0, 20) + '...' : option.name }}</span>
            </template>
          </multiselect>
          </b-form-group>
        </b-col>
        <b-col :cols="nextPasswords?.length ? '3' : '4'" class="selects">
          <b-form-group>
            <label>
              Definir setor
              <span class="help"> (opcional)</span>
            </label>
            <multiselect
              :value="listener"
              track-by="id"
              label="name"
              placeholder="Selecionar..."
              :options="listeners"
              :searchable="false"
              :allow-empty="false"
              :showLabels="false"
              class="with-border"
              @select="setListener"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions">
                Nada por aqui ainda
              </template>
              <template slot="singleLabel" slot-scope="{ option }">
                <span :title="option.name">{{ option.name.length > 20 ? option.name.substring(0, 20) + '...' : option.name }}</span>
              </template>
            </multiselect>
          </b-form-group>
        </b-col>
        <b-col :cols="nextPasswords?.length ? '6' : '4'" class="button-container">
          <button class="form-control" id="add-patient" @click="showAddPatient = true">
            <Plus class="icon" />
            Adicionar paciente à fila
          </button>
          <button v-if="nextPasswords?.length" class="form-control" id="redirect-patients" @click="showBatchRedirect = true">
            Redirecionar pacientes
          </button>
        </b-col>
      </div>
      <div class="divider"/>
      <div class="modules">
        <CurrentPasswordModule />
        <NextPasswordsModule />
      </div>
    </div>
    <add-patient-modal 
      :departmentData="{department, departments}" 
      :modalVisible="showAddPatient" 
      @update:modalVisible="showAddPatient = $event" 
    />
    <batch-redirect-patients-modal 
      :departmentData="{department, departments}" 
      :nextPasswords="nextPasswords" 
      :modalVisible="showBatchRedirect" 
      @update:modalVisible="showBatchRedirect = $event" 
    />
  </b-modal>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import { getCurrentClinic, getCurrentUser } from '@/utils/localStorageManager'
import Close from '@/assets/icons/close.svg'
import ChevronDown from '@/assets/icons/chevron-down.svg'
import Plus from '@/assets/icons/plus.svg'
import CurrentPasswordModule from './CurrentPasswordModule'
import NextPasswordsModule from './NextPasswordsModule'
import AddPatientModal from './AddPatientModal.vue';
import BatchRedirectPatientsModal from './BatchRedirectPatientsModal.vue';

export default {
  components: { Close, ChevronDown, CurrentPasswordModule, NextPasswordsModule, Plus, AddPatientModal, BatchRedirectPatientsModal },
  computed: {
    ...mapState('callPanel', ['department', 'listener', 'departments', 'listeners', 'nextPasswords']),
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      user: getCurrentUser(),
      showAddPatient: false,
      showBatchRedirect: false
    }
  },
  methods: {
    ...mapActions('callPanel', ['setDepartment', 'setListener', 'loadCallPanelDepartments']),
    async onShow() {
      await this.loadCallPanelDepartments()
      await this.getCallPanelCheckIn()
    },
    async getCallPanelCheckIn() {
      const res = await this.api.getCallPanelCheckIn(this.user.id)
      if (res.data) {
        this.setDepartment(res.data.department)
        setTimeout(() => {
          this.setListener(res.data.listener)
        }, 200)
      }
    },
  },
}
</script>
<style lang="scss">
#call-panel-modal {
  font-family: 'Nunito Sans';
  .modal-content {
    min-width: 675px;
    .modal-body {
      padding: 0 !important;

      .header {
        padding: 24px;
        border-bottom: 1px solid var(--neutral-200);

        .title {
          font-weight: 600;
          font-size: 18px;
          color: var(--type-active);
          margin: 0;
        }

        .title1 {
          color: #0c1d59;
          font-family: Red Hat Display;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 134%;
        }

        .form-check-inline1 {
          font-family: Nunito Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 150%;
          align-items: center;
          color: #525c7a;
        }

        .close {
          width: 24px;
          height: 24px;
          fill: black;
          cursor: pointer;
        }
      }

      .body {
        padding: 24px;
      }
      .divider {
        width: 100%;
        height: 1px;
        background-color: var(--neutral-200);
        margin-bottom: 30px;
      }
      .modules {
        width: 100%;
        display: inline-flex;
        gap: 10px;
      }
    }
  }

  .button-container{
    display: flex;
    gap: 10px;
    align-items: flex-end;
    justify-content: center;
    margin-bottom: 10px;
    
    button {
      border-radius: 8px;
      font-size: 16px;
      font-weight: 700;
      transition: 0.3s;
    }

    #add-patient {
      background-color: #E7ECFE;
      color: #305BF2;
      border: none;
      cursor: pointer;

      &:hover {
        background-color: #dadfef;
      }
      .icon {
        width: 16px;
        height: 16px;
        fill: #305BF2;
        margin: 0px 8px 3px 0px;
      }
    }

    #redirect-patients {
      background-color: white;
      color: #305BF2;
      border: 1px solid #305BF2 !important;
      cursor: pointer;

      &:hover {
        background-color: rgb(243, 241, 241);
      }
    }
  }
}
</style>
