<template>
  <b-modal
    :visible="modalVisible"
    id="add-patient-call-panel-modal"
    title="Adicionar paciente à fila"
    size="md"
    hide-header
    hide-footer
    centered
    @hide="closeModal"
  >
    <div class="header d-flex flex-row justify-content-between">
      <p class="title">Adicionar paciente à fila</p>
      <Close class="close" @click="closeModal" />
    </div>
    <div class="body">
      <div class="row mb-2">
        <b-col cols="12">
          <b-form-group>
            <div class="d-flex justify-content-between">
              <label>Paciente</label>
              <div class="checkbox-wrapper">
                <Check v-model="preferential" />
                <span>Preferencial</span>
              </div>
            </div>
            <PatientInput
              required
              v-model="patient"
              :showLabel="false"
              :customLoadingClass="true"
              @select="onSelectPatient"
            >
            </PatientInput>
          </b-form-group>
        </b-col>
      </div>
      <div class="divider" />
      <div v-if="patient" class="row mb-2">
        <b-col cols="12">
          <b-form-group>
            <label> Direcionar paciente para departamento </label>
            <multiselect
              v-model="department"
              track-by="id"
              label="name"
              placeholder="Selecionar..."
              :options="departmentsList"
              :searchable="false"
              :allow-empty="false"
              :showLabels="false"
              class="with-border"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions"> Nada por aqui ainda </template>
            </multiselect>
          </b-form-group>
        </b-col>
      </div>
      <div v-if="appointments.length" class="row mb-2">
        <b-col cols="12">
          <b-form-group>
            <label>
              Agendamento
              <span class="help"> (opcional)</span>
            </label>
            <multiselect
              v-model="appointment"
              track-by="id"
              label="label"
              placeholder="Selecionar..."
              :options="appointments"
              :searchable="false"
              :allow-empty="false"
              :showLabels="false"
              class="with-border"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions"> Nada por aqui ainda </template>
            </multiselect>
          </b-form-group>
        </b-col>
      </div>
      <div class="actions">
        <button id="cancel-button" class="form-control" @click="closeModal">
          Cancelar
        </button>
        <button
          id="confirm-button"
          class="form-control"
          @click="redirectPassword"
          :disabled="isLoading"
        >
          <b-spinner
            v-if="isLoading"
            small
            variant="primary"
            label="Spinning"
          ></b-spinner>
          <span v-else>Confirmar</span>
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Close from '@/assets/icons/close.svg'
import Check from '@/components/General/Check'
import PatientInput from '@/components/General/PatientInput'
import ChevronDown from '@/assets/icons/chevron-down.svg'

export default {
  components: {
    Close,
    Check,
    PatientInput,
    ChevronDown
  },
  props: {
    modalVisible: Boolean,
    departmentData: Object
  },
  data() {
    return {
      preferential: false,
      patient: null,
      department: null,
      departmentsList: [],
      appointments: [],
      appointment: null,
      isLoading: false
    }
  },
  methods: {
    closeModal() {
      this.$emit('update:modalVisible', false)
      this.patient = null
      this.preferential = false
      this.department = null
      this.appointments = []
      this.appointment = null
    },
    onSelectPatient(patient) {
      this.getAppointmentsByPatientToday(patient)
    },
    getAppointmentsByPatientToday(patient) {
      this.api
        .getAppointmentsByPatientToday(patient.id)
        .then(res => {
          this.appointments = res.data.map(el => ({
            ...el,
            label: `${this.moment(el.start_datetime).format(
              'DD/MM/YY HH:mm'
            )} - ${el.doctor ? el.doctor.name : el.room.name}`
          }))
        })
        .catch(err => this.$toast.error(err.message))
    },
    async redirectPassword() {
      this.isLoading = true
      try {
        const data = {
          password: 'ENC000',
          department_id: this.department.id,
          patient_id: this.patient.id,
          appointment_id: this.appointment ? this.appointment.id : null,
          is_preferential: this.preferential,
          professional_id: null,
          listener_id: null,
          attendance_start: null,
          status: 'waiting'
        }
        await this.api.createCallPanelPassword(data)
        this.$toast.success('Paciente adicionado com sucesso')
      } catch (error) {
        this.$toast.error('Ocorreu um erro ao adicionar o paciente à fila')
      } finally {
        this.isLoading = false
        this.closeModal()
      }
    }
  },
  watch: {
    departmentData: {
      handler(newVal) {
        this.departmentsList = newVal.departments
        this.department = newVal.department
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
#add-patient-call-panel-modal {
  font-family: 'Nunito Sans';

  .modal-content {
    .modal-body {
      padding: 0 !important;

      .header {
        padding: 24px;
        border-bottom: 1px solid var(--neutral-200);

        .title {
          font-weight: 600;
          font-size: 18px;
          color: var(--type-active);
          margin: 0;
        }

        .title1 {
          color: #0c1d59;
          font-family: Red Hat Display;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 134%;
        }

        .form-check-inline1 {
          font-family: Nunito Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 150%;
          align-items: center;
          color: #525c7a;
        }

        .close {
          width: 24px;
          height: 24px;
          fill: black;
          cursor: pointer;
        }
      }

      .body {
        padding: 24px;

        .checkbox-wrapper {
          position: relative;
          width: 100%;
          display: inline-flex;
          justify-content: flex-end;
          align-items: unset;
          margin-bottom: 0;
        }

        .actions {
          display: flex;
          justify-content: flex-end;
          width: 100%;
          margin-top: 0;

          button {
            font-family: 'Nunito Sans';
            border-radius: 8px;
            font-weight: 700;
            width: fit-content;
          }

          #cancel-button {
            background-color: transparent;
            color: red;
            border: none !important;
            margin-right: 10px;

            &:hover {
              background-color: #00000018;
            }
          }

          #confirm-button {
            background-color: #305bf2;
            color: white;
          }
        }
      }

      .divider {
        width: 100%;
        height: 1px;
        background-color: var(--neutral-200);
        margin-bottom: 15px;
      }
    }
  }
}
</style>
