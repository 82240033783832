<template>
  <div class="patient-multiselect-call-panel form-group">
    <multiselect
      id="patients"
      v-model="selected"
      track-by="id"
      label="name"
      :placeholder="placeholder"
      :options="patientsList"
      :searchable="false"
      :multiple="true"
      :option-height="40"
      :showLabels="false"
      :showNoResults="true"
      :closeOnSelect="false"
      class="with-border multiple"
      @input="selectOption"
    >
      <template v-if="!loading" slot="caret">
        <div class="chevron">
          <ChevronDown class="icon" />
        </div>
      </template>
      <template slot="selection" slot-scope="{ values }">
        <span class="selectedOptions">
          <p v-if="values.length">
            {{
              values.length === 1
                ? '1 selecionado'
                : `${values.length} selecionados`
            }}
          </p>
          <p v-else>Nenhum paciente selecionado</p>
        </span>
      </template>
      <template slot="option" slot-scope="{ option }">
        <div class="option-container">
          <div v-if="option.patient" class="option-patient">
            <div class="option-name">
              {{ option.patient.name }}
              <span v-if="option.is_preferential" class="preferential-icon">P</span>
            </div>
            <div class="option-container-props">
              <div class="option-props-birthday">
                {{ getBirthday(option.patient.birthday) }}
              </div>
              <span class="props-pipe">|</span>
              <div class="option-props-cpf">
                CPF: {{ getCpf(option.patient.cpf) }}
              </div>
            </div>
          </div>
          <div v-else>
            <div class="option-name">
              {{ option.password }}
              <span v-if="option.is_preferential" class="preferential-icon">P</span>
            </div>
          </div>
        </div>
      </template>
      <template slot="noOptions">
        <div>Nenhum paciente encontrado</div>
      </template>
      <template slot="noResult">
        <div>Nenhum paciente encontrado</div>
      </template>
    </multiselect>
    <div v-if="error" class="custom-invalid-feedback">Campo obrigatório</div>
    <b-spinner
      v-if="loading"
      class="loading"
      :class="{ 'custom-loading-class': customLoadingClass }"
      :variant="customLoadingClass ? 'primary' : 'light'"
      type="grow"
    />
  </div>
</template>
<script>
import moment from 'moment'
export default {
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg')
  },
  props: {
    value: Array,
    label: String,
    disabled: Boolean,
    required: Boolean,
    error: Boolean,
    placeholder: {
      type: String,
      default: 'Selecionar'
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    customLoadingClass: {
      type: Boolean,
      default: false
    },
    patientsList: {
      type: Array,
      default: () => []
    }
  },
  created() {},
  mounted() {
    this.selected = this.patientsList
    this.$emit('input', this.selected)
  },
  data() {
    return {
      selected: [],
      loading: false
    }
  },
  methods: {
    selectOption(option) {
      this.$emit('input', this.selected)
    },
    getBirthday(birthday) {
      if (!birthday) return 'Não informado'
      else if (!moment(birthday).isValid()) return 'Data nasc. inválida'
      return moment(birthday).format('(DD/MM/YYYY)')
    },
    getCpf(cpf) {
      if (!cpf) return 'Não informado'
      return cpf
    }
  }
}
</script>
<style lang="scss">
.patient-multiselect-call-panel {
  position: relative;
  text-align: left !important;

  span.multiselect__option.multiselect__option::before {
    margin-top: 15px;
  }

  .multiselect .multiselect__tags .multiselect__single {
    display: none !important;
  }

  label {
    width: 100%;
    font-family: 'Nunito Sans';
    font-weight: 700;
    font-size: 16px;
    color: #525c7a;
    text-align: left !important;
    color: var(--dark-blue);
    margin-bottom: 4px;
  }
  .help {
    margin-left: 5px;
  }

  .loading {
    position: absolute;
    top: 33px;
    right: 10px;
    z-index: 50;
  }
  .custom-loading-class {
    position: absolute;
    top: 3px !important;
    right: 12px !important;
    z-index: 50;
    transform: scale(-0.5) !important;
  }
  .option-container {
    display: flex;
    flex-direction: column;
    font-family: 'Nunito Sans';
  }
  .option-name {
    font-size: 1rem;
    color: var(--type-active);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
  }
  .option-container-props {
    display: flex;
    flex-direction: row;
    font-size: 0.8rem;
  }
  .option-props-birthday {
    color: var(--type-placeholder);
  }
  .props-pipe {
    margin: 0 5px;
    color: var(--type-placeholder);
  }
  .option-props-cpf {
    color: var(--blue-500);
  }
  .chevron {
    z-index: 10;

    &.icon {
      stroke: var(--neutral-500);
    }
  }
  .clear-button {
    fill: var(--neutral-500) !important;
    stroke: none !important;
  }

  .preferential-icon {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--orange);
    color: white;
    font-size: 11px;
    font-weight: 700;
    padding-top: 2px;
    border-radius: 50%;
    margin-left: 5px;
  }
}
</style>
